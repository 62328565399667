import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UrlService } from '@services/url.service';
import { EtlRoute } from '@shared/models/etl-route.model';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EtlService {
  readonly http = inject(HttpClient);
  readonly urlService = inject(UrlService);
  readonly baseUrl = this.urlService.ETL_URL;

  getRoutes(tenantId: TenantIdType): Observable<EtlRoute[]> {
    const url = `${this.baseUrl}/routes/{tenant}`.replace('{tenant}', tenantId);
    return this.http.post<EtlRoute[]>(url, {});
  }
}
