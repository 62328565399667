import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent {
  printDiv() {
    const printContents = document.getElementById('privacy')?.innerHTML;
    const windowFeatures = 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no';
    const popupWinindow = window.open('', '_blank', windowFeatures);
    popupWinindow?.document.open();
    const htmlText = `<html>
      <head>
        <link rel="stylesheet" type="text/css" href="style.css" />
      </head>
      <body onload="window.print()">
        ${printContents}
      </body>
    </html>`;
    popupWinindow?.document.write(htmlText);
    popupWinindow?.document.close();
  }
}
